import React, {Component, Fragment, useState} from 'react'
import {Button, Col, ControlLabel, FormGroup, Row} from 'react-bootstrap'
import {
    ModalButton,
    ModalButtonWithLoading,
    ModalControls,
    ModalControlsSection,
    ModalControlsSectionGroup,
    ModalError,
    ModalHR,
    ModalPrintButtons,
    ModalSuccessMessage,
    ModalText,
    ModalTitle,
} from '../../common/ModalParts'
import PrintServerErrorModal from '../../common/PrintServerErrorModal'
import Textarea from '../../common/TextArea'
import Checkbox from '../../common/Checkbox'
import Day from '../common/Day'
import Rooms from './Rooms'
import Modal from 'react-modal'
import history from '../../../Modules/helpers/history'
import qs from 'qs'
import LoadingModal from '../../common/LoadingModal'
import {lateCheckOutService} from '../../../Modules/helpers/servicesNames'
import BottomBarButtons, {
    BarButton,
    BarButtonGroup,
} from '../common/BottomBarButtons'
import moment from 'moment'
import {modalDefaultStyles} from '../../../App'
import paymentTypes from '../../../Modules/helpers/paymentTypes'
import {Prepayeds} from './Prepayeds'
import {EnhancedCheckLines} from '../../CheckLines'
import {Notes} from './Notes'
import {ConnectedServices} from '../../Services/Services'
import {EditSection} from '../../common/EditSection'
import {ConnectedValidationErrorsModal} from '../common/ValidationErrorsModal'
import {ConnectedUpgrades} from '../../Services/Upgrades'
import {ConnectedCheckTimes} from '../../Services/CheckTimes'
import {LogsAPI} from '../../../Modules/logs-api'
import {PrintBlankButton} from '../../PrintBlankButton'
import {
    callAlert,
    callModal,
    callPrompt,
    modalTemplates,
} from '../../dynamic-modal'
import {handleWriteCardClick} from '../../../Modules/helpers/handleWriteCardClick'
import {PassportFieldGroup} from '../../PassportFieldGroup'
import {PrintFormButtons} from '../../common/print-form-buttons'
import {useDispatch, useSelector} from 'react-redux'
import {loyaltySelectors} from '../../../Modules/loyalty'
import {reservationSelectors} from '../../../Modules/reservation'
import {getBonusesFromPaidItems} from '../../../Modules/reservation/ReservationModule'
import {MODES} from '../../../Modules/helpers/types'
import {ReservationLayout} from '../common/ReservationLayout'
import {ModalLoyaltyInfo} from '../../common/ModalLoyaltyInfo'
import {Loyalty} from '../../loyalty'
import {RangeDatePicker} from '../../common/DatePicker'
import {Breakfasts} from '../../Services/Breakfasts'
import {Parking} from '../../Services/Parking'
import {
    DebouncedTextField,
    useInputPending,
} from '../../common/DebouncedTextField'
import {TotalSumm} from '../common/TotalSumm'
import {ccAPI} from '../../../Modules/api/ccAPI'
import styled from 'styled-components'
import {settingsSelectors} from '../../../Modules/settings'
import {Link} from 'react-router-dom'
import {ConnectedPaymentMethodErrorsModal} from '../common/PaymentMethodErrorsModal/ConnectedPaymentMethodErrorsModal'
import {PrePaidBreakfasts} from '../../Services/PrepaidBreakfast'
import {cardAPI} from '../../../Modules/api/cardApi'
import {PayedDaysList} from '../common/payedDays/PayedDaysList'
import {TelegramIcon} from '../../common/Icons'
import wa from '../../../Assets/wa.svg'
import {NotPayedDays} from '../common/NotPayedDays'

const NotesLink = styled.div`
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
    border-bottom: 1px dashed;
    margin-bottom: 10px;
    user-select: none;
`

const FlexField = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: end;

    > div {
        flex: 1 1 100%;
        &:last-of-type {
            flex: 0 0 auto;
            padding-bottom: 10px;

            button {
                padding: 0;
                width: 30px;
                height: 30px;
                margin-right: 2px;
            }

            &a:last-of-type {
                margin-right: 0;
            }
        }
    }
`

const handlePrintRefundBlankClick = async reservation => {
    const {action} = await callModal(
        modalTemplates.confirm({
            title: 'Проверка ФИО',
            text: [
                'Проверьте, чтобы в брони было корректное ФИО.',
                'Оно должно быть указано в формате «Фамилия, Имя, Отчество (при наличии)».',
                `Текущее ФИО в брони: ${reservation.guest_name}`,
            ],
            buttons: ['Отмена', 'ФИО указано корректно'],
        })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
        return
    }
    localStorage.setItem(
        'REFUND_DATA',
        JSON.stringify({
            reservation,
        })
    )
    window.open(`/print_refund_blank`, '_blank')

    await callModal(
        modalTemplates.alert({
            title: 'Печать бланка возврата',
            text: [
                'Необходимо удалить возвратные сутки из Ghotel, в т.ч штрафные дни из Ghotel, если такие имеются.',
            ],
        })
    )
}

const SuccessModal = ({
                          isOpen,
                          hideModal,
                          isPrinterOn,
                          printCheckAgain,
                          showWritecardModal,
                      }) => {
    const {receiveForPaid: canReceiveForPaid} = useSelector(
        loyaltySelectors.availableActions
    )

    const isGettingBonusesFromPaidItems = useSelector(
        reservationSelectors.isGettingBonusesFromPaidItems
    )

    const isDoorLocks = useSelector(settingsSelectors.isDoorLocks)

    const dispatch = useDispatch()

    return (
        <Modal
            isOpen={isOpen}
            defaultStyles={{
                ...modalDefaultStyles,
                content: {
                    ...modalDefaultStyles.content,
                    width: 900,
                    maxWidth: 900,
                },
            }}
        >
            <ModalTitle>Успех</ModalTitle>
            <ModalHR/>
            <ModalText>
                <p>Бронь была обновлена.</p>
                <ModalLoyaltyInfo/>
            </ModalText>
            <ModalHR/>
            <ModalControlsSectionGroup>
                {canReceiveForPaid && (
                    <ModalControlsSection title="Лояльность">
                        <ModalButtonWithLoading
                            bsStyle="primary"
                            onClick={() => {
                                dispatch(getBonusesFromPaidItems())
                            }}
                            loading={isGettingBonusesFromPaidItems}
                        >
                            Начислить бонусы за оплаченные позиции
                        </ModalButtonWithLoading>
                    </ModalControlsSection>
                )}

                <ModalControlsSection title="Печать форм">
                    <ModalPrintButtons/>
                </ModalControlsSection>

                <ModalControlsSection title="Печать бланка">
                    <PrintBlankButton component={ModalButton}/>
                </ModalControlsSection>

                {isPrinterOn && (
                    <ModalControlsSection title="Принтер">
                        <ModalButton bsStyle="primary" onClick={printCheckAgain}>
                            Повторная печать чека
                        </ModalButton>
                    </ModalControlsSection>
                )}

                {isDoorLocks && <ModalControlsSection title="Действия с картами">
                    <ModalButton
                        bsStyle="primary"
                        onClick={() => {
                            handleWriteCardClick({
                                showWriteCardModal: () => showWritecardModal(),
                            })
                        }}
                    >
                        Записать карту
                    </ModalButton>
                </ModalControlsSection>}

                <ModalControlsSection title="Действия с окном">
                    <ModalButton
                        bsStyle="danger"
                        onClick={() => {
                            hideModal()
                            history.push('/')
                        }}
                    >
                        Закрыть
                    </ModalButton>
                </ModalControlsSection>
            </ModalControlsSectionGroup>
        </Modal>
    )
}

const ErrorOnSaveModal = props => {
    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Ошибка!</ModalTitle>
            <ModalHR/>
            <ModalText>
                Один или несколько новых дней невозможно добавить, так как они
                принадлежат брони {props.errorPK}
            </ModalText>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    style={{marginLeft: 'auto'}}
                    onClick={props.hideModal}
                >
                    Закрыть
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const ErrorOnDeleteModal = props => {
    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Ошибка!</ModalTitle>
            <ModalHR/>
            <ModalText>Ошибка удаления брони.</ModalText>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    style={{marginLeft: 'auto'}}
                    onClick={props.hideModal}
                >
                    Закрыть
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const AfterMigrationModal = props => {
    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Внимание!</ModalTitle>
            <ModalHR/>
            <ModalText>
                Было выполнено переселение, выписанные карты данной брони сброшены.
                Хотите перезаписать их?
            </ModalText>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    onClick={() => {
                        props.hideModal()
                        LogsAPI.pressAction('Отказаться от перезаписи карт')
                    }}
                >
                    Отмена
                </ModalButton>
                <ModalButton
                    bsStyle="success"
                    style={{marginLeft: 'auto'}}
                    onClick={() => {
                        props.hideModal()
                        LogsAPI.pressAction('Перезаписать карты')
                        props.action()
                    }}
                >
                    Перезаписать
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const AfterExtensionModal = ({isOpen, hideModal}) => {
    return (
        <Modal
            isOpen={isOpen}
            defaultStyles={{
                ...modalDefaultStyles,
                overlay: {
                    ...modalDefaultStyles.overlay,
                    zIndex: 170,
                },
            }}
        >
            <ModalTitle>Внимание!</ModalTitle>
            <ModalHR/>
            <ModalText>
                Бронь продлена. Теперь необходимо добавить документы гостя(-ей) для
                продления регистрации паспортистами.
            </ModalText>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="success"
                    style={{marginLeft: 'auto'}}
                    onClick={hideModal}
                >
                    Ок
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const ChangeDaysCountModal = props => (
    <Modal isOpen={props.isOpen}>
        <ModalTitle>Внимание</ModalTitle>
        <ModalHR/>
        <ModalText>
            <div>
                <p>Дни проживания изменены.</p>
                <p>Уведомите бухгалтерию об изменении дат проживания согласно&nbsp;
                    <a target="_blank"
                       href="https://wf-manuals.zendesk.com/hc/ru/articles/360002140038-Отчет-по-незаездам-сокращению-дат-понижении-категории-">
                        инструкциям</a>.</p>
                <p>Обязательно уведомите паспортистов при выезде <strong>иностранных</strong> гостей раньше, чем по
                    изначальной
                    брони
                    чтобы
                    снять их с учета. Контакты паспортистов находятся <Link to="/telephony">здесь</Link>.</p>
            </div>
        </ModalText>
        <ModalHR/>
        <ModalControls>
            <ModalButton
                bsStyle="danger"
                style={{marginLeft: 'auto'}}
                onClick={props.hideModal}
            >
                Закрыть
            </ModalButton>
        </ModalControls>
    </Modal>
)

const WriteCardModal = ({isOpen, hideModal, action, error, hideError}) => {
    const [isWriting, setIsWriting] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [isWritedAtLeastOnce, setIsWritedAtLeastOnce] = useState(false)

    const isGettingBonusesFromPaidItems = useSelector(
        reservationSelectors.isGettingBonusesFromPaidItems
    )

    function getButtonText() {
        if (isGettingBonusesFromPaidItems) return 'Автоматически начисляем бонусы..'
        if (isWriting) return 'Записываем карту..'
        if (isWritedAtLeastOnce) return 'Записать еще'
        return 'Записать'
    }

    return (
        <Modal isOpen={isOpen} style={{content: {width: 540}}}>
            <ModalTitle>Запись карты</ModalTitle>
            <ModalHR/>
            <ModalText>Приложите карту</ModalText>
            {successMessage && (
                <ModalSuccessMessage>{successMessage}</ModalSuccessMessage>
            )}
            {error && <ModalError>{error}</ModalError>}
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    onClick={() => {
                        hideModal()
                        setIsWriting(false)
                        setSuccessMessage(null)
                        setIsWritedAtLeastOnce(false)
                        hideError()
                        LogsAPI.pressAction('Закрыть диалог записи карты')
                    }}
                >
                    {isWritedAtLeastOnce ? 'Завершить' : 'Отмена'}
                </ModalButton>
                <ModalButtonWithLoading
                    loading={isWriting}
                    bsStyle="success"
                    onClick={async () => {
                        LogsAPI.pressAction('Записать карту')

                        hideError()
                        setIsWriting(true)
                        setSuccessMessage(null)

                        const {isSuccess, error, meta} = await action()

                        if (isSuccess) {
                            setSuccessMessage('Карта успешно записана!')
                            setIsWritedAtLeastOnce(true)

                            LogsAPI.event(`Карта успешно записана (${meta})`)
                        } else {
                            LogsAPI.error(error)
                        }

                        setIsWriting(false)
                    }}
                >
                    {getButtonText()}
                </ModalButtonWithLoading>
            </ModalControls>
        </Modal>
    )
}

class ClearCardModal extends Component {
    state = {
        fetching: false,
        successMessage: null,
        errorMessage: null,
        isCleaned: false,
    }

    clearState = () =>
        this.setState({
            fetching: false,
            successMessage: null,
            errorMessage: null,
            isCleaned: false,
        })

    render() {
        return (
            <Modal isOpen={this.props.isOpen} style={{content: {width: 540}}}>
                <ModalTitle>Очистка</ModalTitle>
                <ModalHR/>
                <ModalText>Приложите карту</ModalText>
                {this.state.successMessage && (
                    <ModalSuccessMessage>{this.state.successMessage}</ModalSuccessMessage>
                )}
                {this.state.errorMessage && (
                    <ModalError>{this.state.errorMessage}</ModalError>
                )}
                <ModalHR/>
                <ModalControls>
                    <ModalButton
                        bsStyle="danger"
                        onClick={() => {
                            this.props.hideModal()
                            this.clearState()
                            LogsAPI.pressAction('Закрыть диалог записи карты')
                        }}
                    >
                        {this.state.isCleaned ? 'Завершить' : 'Отмена'}
                    </ModalButton>
                    {this.props.isOpenedOnDelete && (
                        <ModalButton
                            bsStyle={!this.props.hasCard ? 'success' : 'danger'}
                            onClick={() => {
                                LogsAPI.pressAction('Продолжить удаление брони')
                                this.props.deleteReservation()
                            }}
                        >
                            Продолжить удаление брони
                        </ModalButton>
                    )}
                    <ModalButtonWithLoading
                        loading={this.state.fetching}
                        bsStyle="success"
                        onClick={() => {
                            this.setState({
                                fetching: true,
                                successMessage: null,
                                errorMessage: null,
                            })
                            const {apiData, doorLockType, hasCard} = this.props
                            LogsAPI.pressAction('Очистить карту')
                            if (!hasCard) {
                                this.setState({
                                    fetching: false,
                                    errorMessage: 'В брони нет записанных карт',
                                })
                                LogsAPI.error('В брони нет записанных карт')
                                return
                            }
                            cardAPI[doorLockType].clearCardCall({...apiData})
                                .then(res => {
                                    if (res.status === 'ok') {
                                        this.setState({
                                            successMessage: 'Карта успешно очищена!',
                                            isCleaned: true,
                                        })
                                        LogsAPI.event('Карта успешно очищена')
                                    } else {
                                        this.setState({
                                            errorMessage: res.error,
                                        })
                                        LogsAPI.error(`Неудачная очистка карты: ${res.error}`)
                                    }
                                })
                                .then(() => {
                                    this.setState({fetching: false})
                                })
                        }}
                    >
                        {this.state.isWrited ? 'Очистить еще' : 'Очистить'}
                    </ModalButtonWithLoading>
                </ModalControls>
            </Modal>
        )
    }
}

const SaveButton = ({onClick, isSaving, fromOtherHotel}) => {
    const isSomeInputPending = useInputPending()

    return (
        <BarButton
            bsStyle="success"
            onClick={onClick}
            disabled={fromOtherHotel || isSomeInputPending || isSaving}
        >
            Сохранить
        </BarButton>
    )
}

const RenderModeSelection = ({
                                 selectMode,
                                 loyaltyClientActions,
                                 getChecks,
                                 setChecks,
                             }) => {
    const dispatch = useDispatch()
    return (
        <div style={{width: '100%', height: '100%', display: 'flex'}}>
            <div style={{margin: 'auto', width: '100%', maxWidth: 300}}>
                <Button
                    bsStyle="success"
                    style={{
                        display: 'block',
                        width: '100%',
                        marginBottom: 16,
                        fontSize: 20,
                    }}
                    onClick={() => {
                        LogsAPI.pressAction('Выбрать режим продажи')
                        selectMode(MODES.EDIT)
                        loyaltyClientActions.checkExistingReservation()
                    }}
                >
                    Продажа
                </Button>
                <Button
                    bsStyle="danger"
                    style={{display: 'block', width: '100%', fontSize: 20}}
                    onClick={async () => {
                        LogsAPI.pressAction('Выбрать режим возврата')
                        dispatch(selectMode(MODES.REFUND))
                        await dispatch(getChecks)
                        await dispatch(setChecks)
                    }}
                >
                    Возврат
                </Button>
            </div>
        </div>
    )
}

class EditReservation extends Component {
    state = {
        outsideRangeEnd: null,
        initialTotal: null,
        activeModals: [],
        isErrorOnDeleteShowed: false,
        isClearCardOpened: false,
        isClearCardOpenedOnDelete: false,
        phoneDisabled: true,
        breakfasts: null,
        showNotes: true
    }

    async componentDidMount() {
        const q = qs.parse(this.props.location.search.slice(1))
        await this.props.loadServices()
        await this.props.getReservation(q.pk)
        await this.props.getRegistrationForms()
        this.checkArchiveStatus()
        this.checkWubook()
    }

    componentWillUnmount() {
        this.props.clearReservation()
    }

    openClearCard = () => this.setState({isClearCardOpened: true})
    openClearCardOnDelete = () =>
        this.setState({isClearCardOpened: true, isClearCardOpenedOnDelete: true})
    closeClearCard = () =>
        this.setState({
            isClearCardOpened: false,
            isClearCardOpenedOnDelete: false,
        })

    checkWubook = () => {
        const {modes} = this.props

        if (!modes.wubook) return

        const {loyaltyClientActions} = this.props

        loyaltyClientActions.checkExistingReservation()
    }

    checkArchiveStatus = () => {
        const {isArchived, selectMode, fromOtherHotel} = this.props
        if (isArchived || fromOtherHotel) selectMode(MODES.READONLY)
    }

    showModal = name =>
        this.setState(prev => ({
            activeModals: prev.activeModals.concat(name),
        }))

    hideModal = name =>
        this.setState(prev => ({
            activeModals: prev.activeModals.filter(m => m !== name),
        }))

    onDatesChange = (startDate, endDate) => {
        const fSD = moment(startDate).format('YYYY-MM-DD')
        const fED = moment(endDate).format('YYYY-MM-DD')

        if (this.props.start !== fSD) {
            this.props.onStartChange(fSD)
        }

        if (this.props.end !== fED) {
            this.props.onEndChange(fED)
        }
    }

    getLastID = () => {
        const {reservedDays} = this.props

        const sortedByDate = reservedDays.sort((a, b) => {
            return moment(a.date).isAfter(moment(b.date)) ? 1 : -1
        })
        return sortedByDate[sortedByDate.length - 1].id
    }

    renderPayedDays = modes => {
        const {
            payedDays, isReady, onDayRefund,
            onNotPayedDayMethodChange,
            daysValidationErrors,
            onNotPayedDayPriceChange,
            onNotPayedDayPayedChange,
        } = this.props

        if (payedDays.filter(d => d.payment_date !== '').length === 0) return null

        let content
        if (!isReady)
            content = (<NotPayedDays
                list={payedDays}
                onDayPriceChange={({value, day}) =>
                    onNotPayedDayPriceChange(day.id, value)
                }
                onDayPaymentChange={({value, day}) =>
                    onNotPayedDayMethodChange(day.id, value)
                }
                onDayPayedChange={({value, day}) =>
                    onNotPayedDayPayedChange(day.id, value)
                }
                modes={modes}
                dayRefund={this.props.onDayRefund}
                lastId={this.getLastID()}
                validationErrors={this.props.daysValidationErrors}
            />)
        else content = (<PayedDaysList
            payedDays={payedDays.sort((d1, d2) => new Date(d1.date) - new Date(d2.date))}
            isReady={isReady}
            modes={modes}
            lastID={this.getLastID()}
            onChangePaymentType={onNotPayedDayMethodChange}
            daysValidationErrors={daysValidationErrors}
            dayRefund={onDayRefund}
        />)

        return (
            <EditSection
                name="Оплаченные дни"
                content={content}
            />
        )
    }

    renderNotPayedDays = modes => {
        const {
            notPayedDays,
            onNotPayedDayPriceChange,
            onNotPayedDayMethodChange,
            onNotPayedDayPayedChange,
        } = this.props

        if (notPayedDays.length === 0) return null

        return (
            <EditSection
                name="Неоплаченные дни"
                content={
                    <NotPayedDays
                        list={notPayedDays.sort((d1, d2) => new Date(d1.date) - new Date(d2.date))}
                        onDayPriceChange={({value, day}) =>
                            onNotPayedDayPriceChange(day.id, value)
                        }
                        onDayPaymentChange={({value, day}) =>
                            onNotPayedDayMethodChange(day.id, value)
                        }
                        onDayPayedChange={({value, day}) =>
                            onNotPayedDayPayedChange(day.id, value)
                        }
                        modes={modes}
                        dayRefund={this.props.onDayRefund}
                        lastId={this.getLastID()}
                        validationErrors={this.props.daysValidationErrors}
                    />
                }
            />
        )
    }

    renderExistingDays = modes => {
        return (
            <Fragment>
                {this.renderPayedDays(modes)}
                {this.renderNotPayedDays(modes)}
            </Fragment>
        )
    }

    renderNewDays = modes => {
        const {newDays, notPayedDays, logs} = this.props

        const copyPriceCond = !modes.wubook
        const copyMethodCond = true
        const copyButtonsCond = newDays.length + notPayedDays.length > 0

        return (
            <EditSection
                name="Новые дни"
                content={
                    newDays.length > 0 && (
                        <React.Fragment>
                            {newDays.map(day => (
                                <Day
                                    key={day.id}
                                    day={day}
                                    price={day.price}
                                    method={day.payment_type}
                                    onDayPriceChange={value =>
                                        this.props.onDayPriceChange(day.id, value)
                                    }
                                    onDayPaymentChange={value =>
                                        this.props.onDayMethodChange(day.id, value)
                                    }
                                    onDayPayedChange={value =>
                                        this.props.onDayPayedChange(day.id, value)
                                    }
                                    payed={!!day.payment_date}
                                    modes={modes}
                                    validationErrors={this.props.daysValidationErrors}
                                />
                            ))}
                        </React.Fragment>
                    )
                }
                footer={
                    <React.Fragment>
                        {copyButtonsCond && (
                            <div style={{marginBottom: 10}}>
                                {copyPriceCond && (
                                    <Button
                                        bsStyle="primary"
                                        onClick={() => this.props.onDaySummCopy()}
                                    >
                                        Копировать стоимость с первого дня
                                    </Button>
                                )}
                                {copyMethodCond && (
                                    <Button
                                        style={{marginLeft: 5}}
                                        bsStyle="primary"
                                        onClick={() => this.props.onDayMethodCopy()}
                                    >
                                        Копировать метод с первого дня
                                    </Button>
                                )}
                            </div>
                        )}
                        <div>
                            <Button
                                onClick={async () => {
                                    const hasCheckOutService = this.props.allServices.some(
                                        s => s.service === lateCheckOutService && s.payed
                                    )

                                    if (hasCheckOutService) {
                                        logs.logError(
                                            'В данной брони есть уже оплаченный поздний выезд, который не может быть изменен после добавления нового дня. Чтобы добавить день, необходимо сделать возврат услуги позднего выезда в режиме возврата.'
                                        )

                                        const {action} = await callModal(
                                            modalTemplates.confirm({
                                                title: 'Ошибка',
                                                text: [
                                                    'В данной брони есть уже оплаченный поздний выезд, который не может быть изменен после добавления нового дня.',
                                                    'Чтобы добавить день, необходимо сделать возврат услуги позднего выезда.',
                                                ],
                                                buttons: ['Отмена', 'Перейти в режим возврата'],
                                            })
                                        )

                                        if (action !== modalTemplates.confirm.actions.confirm) {
                                            return
                                        }

                                        // Reload page
                                        history.go(0)
                                    }

                                    this.props.onReservationDayAdd()
                                }}
                                bsStyle="success"
                            >
                                Добавить сутки
                            </Button>
                            {this.props.reservedDays.length > 1 && newDays.length > 0 ? (
                                <Button
                                    onClick={() => this.props.onReservationDayRemove()}
                                    style={{marginLeft: 5}}
                                    bsStyle="danger"
                                >
                                    Убрать сутки
                                </Button>
                            ) : null}
                        </div>
                    </React.Fragment>
                }
            />
        )
    }

    showErrorOnDelete = () => this.setState({isErrorOnDeleteShowed: true})
    hideErrorOnDelete = () => this.setState({isErrorOnDeleteShowed: false})

    deleteReservation = async (isConfirmed = false) => {
        const {
            hasCard,
            days,
            allServices,
            refundedItemsByType,
            isWashingOperationsOn,
        } = this.props

        const {days: refundedDays} = refundedItemsByType

        if (isConfirmed) {
            LogsAPI.pressAction('Удалить бронь')
        } else {
            LogsAPI.event('Выполняется удаление брони')
        }

        const servicesToCheck = allServices.filter(item => !(item.service === 'Стирка' && isWashingOperationsOn))

        const hasAnyItem =
            days.length || servicesToCheck.length || refundedDays.length

        if (hasAnyItem) {
            const firstDay = days[0] || refundedDays[0]

            const isPayedByBank = firstDay.payment_type === paymentTypes.bank

            if (!isPayedByBank) {
                callModal(
                    modalTemplates.alert({
                        title: 'Ошибка',
                        text: [
                            'Удаление брони невозможно, пока не будет сделан возврат всех дней и услуг (кроме стирок при новом режиме стирки).',
                            'Для возврата нажмите кнопку «Возврат» у каждого дня/услуги и сохраните бронь.',
                        ],
                    })
                )
                return
            }
        }

        if (hasCard && !isConfirmed) {
            this.openClearCardOnDelete()
            return
        }

        const {action} = await callModal(
            modalTemplates.confirm({
                text: 'Вы точно хотите удалить бронь? Её нельзя будет восстановить.',
            })
        )

        if (action !== modalTemplates.confirm.actions.confirm) {
            return
        }

        const {wasDeleted, wasCanceled} = await this.props.deleteReservation()

        if (wasCanceled) {
            return
        }

        if (!wasDeleted) {
            callModal(
                modalTemplates.alert({
                    title: 'Ошибка',
                    text: 'Не удалось удалить бронь',
                })
            )
            return
        }

        history.push('/')
    }
    phoneChange = async () => {
        callPrompt({
            title: 'Проверка',
            text: [
                'Введите пароль для смены телефона',
                'Запросите его у админ. директора',
            ],
        }).then(async password => {
            if (password == null) {
                return
            }
            await ccAPI
                .getChangePhonePassword(password)
                .then(() => {
                    this.setState({
                        phoneDisabled: !this.props.modes.edit && !this.props.modes.wubook,
                    })
                })
                .catch(() => {
                    callAlert({
                        title: 'Ошибка',
                        text: 'Неправильный пароль',
                    })
                })
        })
    }

    showNotes = () => {
        this.setState({showNotes: !this.state.showNotes})
    }

    render() {
        const {
            isModeSelected,
            modes,
            daysDates,
            generalValidationErrors,
            wasReservationExtended,
            isDoorLocks
        } = this.props

        if (this.props.isFetching) {
            return <LoadingModal isOpen={true}/>
        }
        if (!isModeSelected) {
            return (
                <RenderModeSelection
                    selectMode={this.props.selectMode}
                    loyaltyClientActions={this.props.loyaltyClientActions}
                    getChecks={this.props.getChecks}
                    setChecks={this.props.setChecks}
                />
            )
        }
        return (
            <>
                <ReservationLayout
                    left={
                        <>
                            <EditSection
                                name="Общие данные"
                                content={
                                    <Row>
                                        <Col xs={3}>
                                            <Rooms
                                                lateCheckoutList={this.props.lateCheckoutList}
                                                getLateCheckOut={(date) => this.props.getLateCheckOutList(date)}
                                                migrations={this.props.migrations}
                                                emptyDateSegmentsBetweenInitialMigrations={
                                                    this.props.emptyDateSegmentsBetweenInitialMigrations
                                                }
                                                lastDayDate={this.props.lastDayDate}
                                                reservedDays={this.props.reservedDays}
                                                reservedDaysRooms={this.props.reservedDaysRooms}
                                                rooms={this.props.rooms}
                                                days={this.props.days.map(d => ({
                                                    text: moment(d).format('DD MMMM'),
                                                    value: d,
                                                }))}
                                                daysDates={daysDates}
                                                setRooms={this.props.setRooms}
                                                doMigration={async (date, room) => {
                                                    await this.props.doMigration(date, room)
                                                    if (this.props.hasCard === 0) return
                                                    this.showModal('aftermigration')
                                                }}
                                                reservationEnd={this.props.end}
                                                availableRooms={this.props.availableRooms}
                                                newRoomID={this.props.newRoomID}
                                                isReady={this.props.isReady}
                                                start={this.props.start}
                                                modes={modes}
                                                validationState={
                                                    generalValidationErrors['rooms'] ? 'error' : null
                                                }
                                                lcode={this.props.lcode}
                                            />
                                            {this.props.roomDescription && (
                                                <FormGroup>
                                                    <ControlLabel>Описание комнаты</ControlLabel>
                                                    <p
                                                        style={{
                                                            fontSize: 20,
                                                            fontWeight: 300,
                                                        }}
                                                    >
                                                        {this.props.roomDescription}
                                                    </p>
                                                </FormGroup>
                                            )}
                                            <DebouncedTextField
                                                type="number"
                                                label="Количество взрослых гостей"
                                                value={this.props.guestsNumber}
                                                onChange={this.props.onGuestsNumberChange}
                                                error={generalValidationErrors['guests_number']}
                                                containerStyle={{marginBottom: 10}}
                                                disabled={modes.readonly}
                                            />
                                            {this.props.childrenNumber > 0 && (
                                                <FormGroup>
                                                    <ControlLabel>Количество детей</ControlLabel>
                                                    <p
                                                        style={{
                                                            fontSize: 20,
                                                            fontWeight: 300,
                                                        }}
                                                    >
                                                        {this.props.childrenNumber}
                                                    </p>
                                                </FormGroup>
                                            )}
                                            <DebouncedTextField
                                                label="ФИО гостя"
                                                placeholder="Иванов Иван Иванович"
                                                value={this.props.name}
                                                onChange={this.props.onNameChange}
                                                disabled={
                                                    modes.readonly || (
                                                    !modes.edit &&
                                                    !modes.wubook &&
                                                    !modes.refund
                                                    )
                                                }
                                                error={generalValidationErrors['guest_name']}
                                                containerStyle={{marginBottom: 10}}
                                            />
                                            <PassportFieldGroup/>
                                            <DebouncedTextField
                                                disabled={modes.readonly}
                                                label="Номер брони"
                                                placeholder="123456789"
                                                value={this.props.bookingNumber}
                                                onChange={this.props.onBookingNumberChange}
                                                containerStyle={{marginBottom: 10}}
                                            />
                                            <FlexField>
                                                <DebouncedTextField
                                                    label="Телефон гостя"
                                                    placeholder="+71234567890"
                                                    value={this.props.phone}
                                                    onChange={this.props.onPhoneChange}
                                                    disabled={
                                                        this.props.isLoyaltyAllowed &&
                                                        this.state.phoneDisabled
                                                    }
                                                    error={generalValidationErrors['guest_phone']}
                                                    containerStyle={{
                                                        marginBottom: 10,
                                                        display: 'inline-block',
                                                    }}
                                                />
                                                <div>
                                                    {(modes.edit || modes.wubook) &&
                                                        this.props.isLoyaltyAllowed && (
                                                            <Button
                                                                onClick={this.phoneChange}
                                                                size="small"
                                                                className={'btn-success'}
                                                            >
                                                                <span className="glyphicon glyphicon-pencil"/>
                                                            </Button>
                                                        )}
                                                    <a href={`https://t.me/${this.props.phone}`} target="_blank">
                                                        <Button
                                                            size="small"
                                                            className={'btn-primary'}>
                                                            <TelegramIcon/>
                                                        </Button>
                                                    </a>

                                                    <a href={`https://wa.me/${this.props.phone}`} target="_blank">
                                                        <Button
                                                            size="small"
                                                            className={'btn-success'}>
                                                            <img src={wa} alt="WA"/>
                                                        </Button>
                                                    </a>
                                                </div>
                                            </FlexField>
                                            <DebouncedTextField
                                                label="Почта гостя"
                                                placeholder="ivanov@gmail.com"
                                                value={this.props.mail}
                                                onChange={this.props.onMailChange}
                                                disabled={!modes.edit && !modes.wubook}
                                                containerStyle={{marginBottom: 10}}
                                                error={generalValidationErrors['guest_mail']}
                                            />
                                            {this.props.channelName && (
                                                <FormGroup>
                                                    <ControlLabel>Источник брони</ControlLabel>
                                                    <p
                                                        style={{
                                                            fontSize: 20,
                                                            fontWeight: 300,
                                                        }}
                                                    >
                                                        {this.props.channelName}
                                                    </p>
                                                </FormGroup>
                                            )}
                                            {this.props.createdAt && (
                                                <FormGroup>
                                                    <ControlLabel>Дата бронирования</ControlLabel>
                                                    <p
                                                        style={{
                                                            fontSize: 20,
                                                            fontWeight: 300,
                                                        }}
                                                    >
                                                        {this.props.createdAt}
                                                    </p>
                                                </FormGroup>
                                            )}
                                            <FormGroup>
                                                <ControlLabel>Карты</ControlLabel>
                                                <p
                                                    style={{
                                                        fontSize: 20,
                                                        fontWeight: 300,
                                                    }}
                                                >
                                                    {this.props.hasCard}
                                                </p>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4}>
                                            <NotesLink onClick={this.showNotes}>
                                                {this.state.showNotes ? 'Скрыть примечания' : 'Показать примечания'}
                                            </NotesLink>
                                            {this.state.showNotes && (
                                                <>
                                                    <DebouncedTextField
                                                        componentType="textarea"
                                                        label="Примечание"
                                                        placeholder="Полезное примечание"
                                                        value={this.props.note}
                                                        onChange={this.props.onNoteChange}
                                                        disabled={modes.refund || modes.readonly}
                                                        containerStyle={{marginBottom: 10}}
                                                    />
                                                    <Textarea
                                                        defaultValue={this.props.clientNote}
                                                        onChange={this.onClientNoteChange}
                                                        name="Примечание клиента"
                                                        placeholder="Примечание клиента"
                                                        style={{marginBottom: 10}}
                                                        formStyle={{minHeight: 250}}
                                                        disabled={true}
                                                    />
                                                    <Textarea
                                                        defaultValue={this.props.wubookNote}
                                                        onChange={this.onWubookNoteChange}
                                                        name="Примечание Wubook"
                                                        placeholder="Примечание из Wubook"
                                                        style={{marginBottom: 10}}
                                                        formStyle={{minHeight: 250}}
                                                        disabled={true}
                                                    />
                                                </>)}
                                        </Col>
                                        <Col xs={5}>
                                            <Checkbox
                                                name="Оплата"
                                                title="Бронь оплачена полностью"
                                                value={this.props.payed}
                                                onChange={value =>
                                                    this.props.onReservationPayedChange(value)
                                                }
                                                formStyle={{marginBottom: 15}}
                                                disabled={!modes.edit && !modes.wubook}
                                            />
                                            {!this.props.isReady && (
                                                <Checkbox
                                                    name="Возвратность"
                                                    title="Бронь невозвратная"
                                                    value={this.props.hasRefund}
                                                    onChange={() =>
                                                        this.props.onRefundChange(!this.props.hasRefund)
                                                    }
                                                    disabled={modes.readonly}
                                                    formStyle={{marginBottom: 15}}
                                                />
                                            )}
                                            <RangeDatePicker
                                                startLabel="Дата заезда"
                                                endLabel="Дата выезда"
                                                start={this.props.start}
                                                end={this.props.end}
                                                startDisabled={modes.readonly || this.props.isReady}
                                                startEditByPassword={
                                                    !this.props.isReady &&
                                                    (this.props.isPayedInAdvance || this.props.payed)
                                                }
                                                startMinDate={
                                                    this.props.isReady
                                                        ? new Date(this.props.start)
                                                        : moment(new Date(this.props.start))
                                                            .add(-1, 'day')
                                                            .toDate()
                                                }
                                                endDisabled={modes.readonly || (!modes.edit && !modes.wubook)}
                                                // первый элемент - уменьшение даты, второй - увеличение
                                                endEditByPassword={[
                                                    !this.props.isReady &&
                                                    (this.props.isPayedInAdvance || this.props.payed),
                                                    false,
                                                ]}
                                                onStartChange={this.props.onStartChange}
                                                onEndChange={this.props.onEndChange}
                                                endMinDate={
                                                    this.props.isReady
                                                        ? moment(new Date(this.props.lastPaidDay))
                                                            .add(1, 'day')
                                                            .toDate()
                                                        : moment(new Date(this.props.start))
                                                            .add(1, 'day')
                                                            .toDate()
                                                }
                                            />
                                            <Prepayeds data={this.props.prepayeds}/>
                                            <Notes data={this.props.notes}/>
                                        </Col>
                                    </Row>
                                }
                            />

                            {this.renderExistingDays(modes)}
                            {(modes.edit || modes.wubook) && this.renderNewDays(modes)}

                            <ConnectedCheckTimes
                                availableActions={{
                                    create: modes.edit || modes.wubook,
                                    update: modes.edit || modes.wubook,
                                    copy: modes.edit || modes.wubook,
                                    delete: modes.edit || modes.wubook,
                                    refund: modes.refund,
                                }}
                            />
                            <ConnectedServices
                                availableActions={{
                                    create: modes.edit || modes.wubook,
                                    update: modes.edit || modes.wubook,
                                    copy: modes.edit || modes.wubook,
                                    delete: modes.edit || modes.wubook,
                                    refund: modes.refund,
                                }}
                            />
                            <ConnectedUpgrades
                                availableActions={{
                                    create: modes.edit || modes.wubook,
                                    update: modes.edit || modes.wubook,
                                    copy: modes.edit || modes.wubook,
                                    delete: modes.edit || modes.wubook,
                                    refund: modes.refund,
                                }}
                            />
                            <Breakfasts
                                availableActions={{
                                    create: modes.edit || modes.wubook,
                                    update: modes.edit || modes.wubook,
                                    copy: modes.edit || modes.wubook,
                                    delete: modes.edit || modes.wubook,
                                    refund: modes.refund,
                                }}
                            />
                            {(modes.edit || modes.wubook || modes.refund) && (
                                <PrePaidBreakfasts
                                    prePayedBreakfast={this.props.prePayedBreakfast}
                                />
                            )}
                            <Parking
                                availableActions={{
                                    create: modes.edit || modes.wubook,
                                    update: modes.edit || modes.wubook,
                                    copy: modes.edit || modes.wubook,
                                    delete: modes.edit || modes.wubook,
                                    refund: modes.refund,
                                }}
                                bookingNumber={this.props.bookingNumber}
                            />
                        </>
                    }
                    right={
                        <>
                            <EnhancedCheckLines/>
                            {(modes.edit || modes.wubook) && (
                                <React.Fragment>
                                    <Loyalty/>
                                </React.Fragment>
                            )}
                        </>
                    }
                    toolbar={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                transform: 'translateZ(0)',
                                backgroundColor: '#fff',
                                boxShadow: '0 -2px 5px 0 rgba(0, 0, 0, .27)',
                                padding: 25,
                            }}
                        >
                            <TotalSumm/>
                            <BottomBarButtons>
                                <PrintFormButtons buttonGroupComponent={BarButtonGroup}/>
                                <PrintBlankButton component={BarButton}/>
                                {modes.refund && (
                                    <BarButton
                                        bsStyle="primary"
                                        onClick={() =>
                                            handlePrintRefundBlankClick(this.props.reservation)
                                        }
                                    >
                                        Печать бланка возврата
                                    </BarButton>
                                )}
                                {(isDoorLocks ? modes.edit || modes.wubook : false) &&
                                    this.props.initialData.isReady &&
                                    (this.props.initialData.hasRefund ||
                                        this.props.initialData.reserved_days.some(
                                            d => d.payment_date !== ''
                                        )) && (
                                        <BarButton
                                            bsStyle="primary"
                                            onClick={() => {
                                                handleWriteCardClick({
                                                    showWriteCardModal: () => this.showModal('writecard'),
                                                })
                                            }}
                                        >
                                            Записать карту
                                        </BarButton>
                                    )}
                                {modes.refund && (
                                    <BarButton
                                        bsStyle="warning"
                                        onClick={() => {
                                            LogsAPI.pressAction('Открыть диалог очистки карт')
                                            this.openClearCard()
                                        }}
                                        disabled={modes.readonly || modes.edit}
                                    >
                                        Очистить карту
                                    </BarButton>
                                )}
                                <BarButton
                                    bsStyle="danger"
                                    onClick={() => {
                                        LogsAPI.pressAction('Сбросить карты')
                                        this.props.resetCards({silent: false})
                                    }}
                                >
                                    Сбросить карты
                                </BarButton>
                                <SaveButton
                                    onClick={() =>
                                        this.props.saveReservation({
                                            modes,
                                            showWritecardModal: () => this.showModal('writecard'),
                                            onSuccess: () => {
                                                if (wasReservationExtended) {
                                                    this.showModal('after-extension')
                                                }
                                            },
                                        })
                                    }
                                    isSaving={this.props.isSaving}
                                    fromOtherHotel={this.props.fromOtherHotel}
                                />
                            </BottomBarButtons>
                        </div>
                    }
                    warning={
                        <span>
            {this.props.fromOtherHotel ? <>Данная бронь <b>находится в другом отеле</b>, редактирование невозможно!</> :
                this.props.isArchived && <>Данная бронь <b>архивирована</b>, редактирование невозможно!</>}
            </span>
                    }
                    showWarning={this.props.isArchived || this.props.fromOtherHotel}
                />

                <SuccessModal
                    isOpen={this.props.showSuccessModal}
                    pk={this.props.reservation.pk}
                    reservation={this.props.reservation}
                    settings={this.props.settings}
                    hideModal={this.props.hideSuccessModal}
                    isWubook={!this.props.reservation.isReady}
                    showWritecardModal={() => {
                        LogsAPI.pressAction('Открыть диалог записи карт')
                        this.showModal('writecard')
                    }}
                    resetCards={() => {
                        LogsAPI.pressAction('Сбросить карты')
                        this.props.resetCards()
                    }}
                    isPrinterOn={this.props.isPrinterOn}
                    printCheckAgain={this.props.printCheckAgain}
                    isLoyaltyEnabled={this.props.isLoyaltyEnabled}
                    isLoyaltyUsed={this.props.isLoyaltyUsed}
                    loyaltyPurchaseData={this.props.loyaltyPurchaseData}
                />
                <ConnectedValidationErrorsModal/>
                <ConnectedPaymentMethodErrorsModal/>
                <ErrorOnSaveModal
                    isOpen={this.props.showErrorModal}
                    errorPK={this.props.errorPK}
                    hideModal={this.props.hideErrorModal}
                />
                <ErrorOnDeleteModal
                    isOpen={this.state.isErrorOnDeleteShowed}
                    hideModal={this.hideErrorOnDelete}
                />
                <PrintServerErrorModal
                    isOpen={this.props.isPrintServerErrorModalActive}
                    hideModal={this.props.hidePrintServerError}
                    tryAgainAction={() => this.props.saveReservation({modes})}
                />
                <AfterMigrationModal
                    isOpen={this.state.activeModals.includes('aftermigration')}
                    hideModal={() => this.hideModal('aftermigration')}
                    action={() => this.showModal('writecard')}
                />
                <WriteCardModal
                    isOpen={this.state.activeModals.includes('writecard')}
                    hideModal={() => this.hideModal('writecard')}
                    action={this.props.writeCard}
                    error={this.props.writecardError}
                    hideError={this.props.clearWritecardError}
                />
                <ClearCardModal
                    isOpen={this.state.isClearCardOpened}
                    isOpenedOnDelete={this.state.isClearCardOpenedOnDelete}
                    hideModal={this.closeClearCard}
                    deleteReservation={() => this.deleteReservation(true)}
                    hasCard={this.props.hasCard}
                    apiData={this.props.apiData}
                    doorLockType={this.props.doorLockType}
                />
                <AfterExtensionModal
                    isOpen={this.state.activeModals.includes('after-extension')}
                    hideModal={() => this.hideModal('after-extension')}
                />
                <ChangeDaysCountModal
                    isOpen={this.props.showChangeDaysCountModal}
                    hideModal={this.props.hideChangeDaysCountModal}
                />
                <LoadingModal isOpen={this.props.isLoading}/>
            </>
        )
    }
}

export default EditReservation
